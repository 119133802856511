import {
  SET_DISPLAY_NAME,
  SET_EMOJI_ON,
  SET_LOGO,
  SET_PASSWORD_CHANGE_AVAILABILITY,
  SET_SELECTION_TYPE,
  SET_STYLES,
  SET_TITLE,
} from '~/types/types';

export const setTitle = (title) => ({
  type: SET_TITLE,
  payload: title,
});

export const setStyles = (styles) => ({
  type: SET_STYLES,
  payload: styles,
});

export const setLogo = (logo) => ({
  type: SET_LOGO,
  payload: logo,
});

export const setDisplayName = (displayName) => ({
  type: SET_DISPLAY_NAME,
  payload: displayName,
});

export const setIsEmojiOn = (isOn) => ({
  type: SET_EMOJI_ON,
  payload: isOn,
});

export const setACHSelectionType = (type) => ({
  type: SET_SELECTION_TYPE,
  payload: type,
});
export const setPasswordChangeAvailability = (available) => ({
  type: SET_PASSWORD_CHANGE_AVAILABILITY,
  payload: available,
});
