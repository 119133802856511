import { ConfigProvider } from 'antd';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';

import { AppMediaContextProvider } from '~/contexts/AppMedia';
import { HighlightedContextProvider } from '~/contexts/highlighted';
import { NotificationProvider } from '~/contexts/notifications';
import { MarketOpenProvider } from '~/contexts/MarketStatus';

import App from './App.jsx';
import store from '@store';

import { ErrorPageTemplate } from '~/ui/layouts/ErrorPageTemplate';
import { theme } from './ui/theme';

export default function AppProvider() {
  return (
    <ErrorBoundary fallback={<ErrorPageTemplate loadStyles />}>
      <Provider store={store}>
        <ConfigProvider theme={theme}>
          <MarketOpenProvider>
            <AppMediaContextProvider>
              <BrowserRouter>
                <HighlightedContextProvider>
                  <NotificationProvider>
                    <App />
                  </NotificationProvider>
                </HighlightedContextProvider>
              </BrowserRouter>
            </AppMediaContextProvider>
          </MarketOpenProvider>
        </ConfigProvider>
      </Provider>
    </ErrorBoundary>
  );
}
