import { createContext, FC, PropsWithChildren, useEffect, useMemo } from 'react';
import * as RD from '@devexperts/remote-data-ts';
import { constVoid, pipe } from 'fp-ts/function';
import isAfter from 'date-fns/isAfter';

import { useMarketInfo, MarketInfoData } from '~/entities/market';

type ContextValue = {
  marketData: MarketInfoData;
  loadMarketData: VoidFunction;
};

const DEFAULT_CONTEXT_VALUE: ContextValue = {
  marketData: RD.initial,
  loadMarketData: constVoid,
};

export const MarketStatusContext = createContext<ContextValue>(DEFAULT_CONTEXT_VALUE);

export const MarketOpenProvider: FC<PropsWithChildren> = (props) => {
  const [marketInfo, loadMarketInfo] = useMarketInfo();

  useEffect(
    () => {
      loadMarketInfo();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const interval = setInterval(() => {
      pipe(
        marketInfo,
        RD.map((info) => {
          const now = new Date();

          if ((info.isOpen && isAfter(now, info.closeDateTime)) || (!info.isOpen && isAfter(now, info.openDateTime))) {
            loadMarketInfo();
          }
        }),
      );
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [marketInfo, loadMarketInfo]);

  const value = useMemo(
    () => ({
      marketData: marketInfo,
      loadMarketData: loadMarketInfo,
    }),
    [marketInfo, loadMarketInfo],
  );

  return <MarketStatusContext.Provider value={value}>{props.children}</MarketStatusContext.Provider>;
};
