import { authAPI } from '~/api';
import { privateAPI } from '~/api/base';
import { getRefreshToken, INVALID_TOKEN_CODE, saveAccessToken } from '~/authentication/auth';
import { getCredentials } from '~/domain/services/auth';
import { AxiosInterceptorManager } from 'axios';

export const keepDoing = <T>(data: T) => data;

type HeadersParams = {
  headers?: {
    Authorization?: string;
  };
};
type Test = Parameters<AxiosInterceptorManager<HeadersParams>['use']>[0];

export const addHeadersInterceptor: Test = (config) => {
  const { token } = getCredentials();
  if (!config.headers) {
    config.headers = {};
  }
  config.headers.Authorization = `Bearer ${token}`;
  return config;
};

export const refreshTokenInterceptor = async (error: any) => {
  const { data } = error.response || {};
  const config = error?.config;

  if (data?.code === INVALID_TOKEN_CODE && config) {
    const refreshToken = getRefreshToken();

    if (refreshToken) {
      const res = await authAPI.refreshToken(refreshToken);
      const { access } = res.data;

      if (access) {
        saveAccessToken(access);
        return privateAPI.request(config);
      }
    }
  }

  return error;
};
