import axios from 'axios';
import axiosRetry from 'axios-retry';

import { API_DOMAIN } from '~/constants/constants';
import * as interceptors from '~/api/interceptors';

const baseConfig = {
  baseURL: API_DOMAIN,
};

type PrivateApiParams = {
  validateStatus?: (statuss: number) => boolean;
  retries?: number;
  baseURL?: string;
};

declare module 'axios-retry' {
  export interface IAxiosRetryConfig {
    retryCount?: number;
  }
}

export const getPrivateAPI = ({ retries = 2, ...config }: PrivateApiParams = {}) => {
  const api = axios.create({ ...baseConfig, ...config });

  if (typeof retries === 'number') {
    axiosRetry(api, {
      retries,
      retryCondition: (e) => {
        if (e.config) {
          const axiosConfig = e.config['axios-retry'];
          const currentRetriesCount =
            axiosConfig?.retryCount !== undefined ? axiosConfig?.retryCount : axiosConfig?.retries;

          if (
            currentRetriesCount !== undefined &&
            currentRetriesCount < retries &&
            (e.config.method || '').toLowerCase() === 'get'
          ) {
            return true;
          }
        }
        return false;
      },
    });
  }

  api.interceptors.request.use(interceptors.addHeadersInterceptor);
  api.interceptors.response.use(interceptors.keepDoing, interceptors.refreshTokenInterceptor);

  return api;
};

const newConfig = {
  validateStatus: (status: number) => status >= 200 && status < 400,
};

export const privateAPI = getPrivateAPI(newConfig);
export default axios.create(baseConfig);

export const privateAPIv2 = getPrivateAPI({
  ...newConfig,
  baseURL: `${API_DOMAIN}/v2`,
});
