import { TOKEN_LOCAL_STORAGE_NAME, REFRESH_TOKEN_LOCAL_STORAGE_NAME } from '~/constants/constants';
import { persist, retrieve, remove } from '~/utils/persistence';

export const INVALID_TOKEN_CODE = 'token_not_valid';

export const getAccessToken = () => retrieve(TOKEN_LOCAL_STORAGE_NAME);
export const getRefreshToken = () => retrieve(REFRESH_TOKEN_LOCAL_STORAGE_NAME);

export const saveAccessToken = (token) => persist(TOKEN_LOCAL_STORAGE_NAME, token);
export const saveRefreshToken = (token) => persist(REFRESH_TOKEN_LOCAL_STORAGE_NAME, token);

export const removeAccessToken = () => remove(TOKEN_LOCAL_STORAGE_NAME);
export const removeRefreshToken = () => remove(REFRESH_TOKEN_LOCAL_STORAGE_NAME);

export const ACCESS_TOKEN_PARAM = 'access_token';
export const REFRESH_TOKEN_PARAM = 'refresh_token';
export const NEXT_PATH_PARAM = 'next';
