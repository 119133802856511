import React, { useContext } from 'react';

export const AppContext = React.createContext(null);
export const SettingsContext = React.createContext({});

type ThemeContextType = {
  showEmoji: boolean;
  displayName: string;
  logoURL: string;
};

export const ThemeContext = React.createContext<ThemeContextType>({
  showEmoji: false,
  displayName: '',
  logoURL: '',
});

export const useThemeContext = () => {
  return useContext(ThemeContext);
};
