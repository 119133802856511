import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as routes from '~/routes/names';

import { AppContext } from '~/contexts/app';
import { useCustomization } from '~/domain/effects/application';
import { CompanySettingsContext, useSettings } from '~/services/company';

import { ErrorWrapper } from '@components/utils/errors';

import { selectIsAuthenticated } from '@store/authentication/selectors';
import { selectOrganization } from '@store/organization/selectors';

import { dispatchCompanyDefaults } from '@utils/application';
import { usePrivateNotifications } from '~/domain/effects/notification';

const PrivateRoute = ({
  component: Component,
  additionalPermissions = null,
  noAdditionalPermissionsComponent = null,
  ...rest
}) => {
  const organization = useSelector(selectOrganization);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const companySettings = useSettings(organization);
  useCustomization(organization);
  const appDispatch = useContext(AppContext);

  /* todo re-check render */
  if (isAuthenticated) {
    usePrivateNotifications();
  }

  useEffect(() => {
    dispatchCompanyDefaults(appDispatch, companySettings);
  }, [companySettings]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          if (!additionalPermissions && noAdditionalPermissionsComponent) {
            return noAdditionalPermissionsComponent(props);
          }

          return (
            <CompanySettingsContext.Provider value={companySettings}>
              <ErrorWrapper>
                <Component {...props} />
              </ErrorWrapper>
            </CompanySettingsContext.Provider>
          );
        }

        return (
          <Redirect
            to={{
              pathname: routes.ERROR_PAGE,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
