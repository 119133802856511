import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { constVoid } from 'fp-ts/function';
import { notification } from 'antd';
import { NotificationPlacement } from 'antd/es/notification/interface';

import { checkDeviceSize, useAppMediaContext } from '../AppMedia';
import * as S from './Notification.styled';

export enum NotificationType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

type NotificationParams = {
  message: React.ReactNode;
  description?: React.ReactNode;
  placement: NotificationPlacement;
  type: NotificationType;
};

const getParamsByType = (type: NotificationType) => {
  switch (type) {
    case NotificationType.SUCCESS: {
      return {
        icon: <S.SuccessIcon />,
      };
    }
    case NotificationType.ERROR: {
      return {
        icon: <S.ErrorIcon />,
      };
    }
  }
};

type OpenNotification = (params: NotificationParams) => void;

type ContextValue = {
  openNotification: OpenNotification;
};

export const NotificationContext = React.createContext<ContextValue>({
  openNotification: constVoid,
});

export const NotificationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { deviceSize } = useAppMediaContext();
  const isMobile = !checkDeviceSize.moreOrEqual.md(deviceSize);
  const [notificationApi, notificationContextHolder] = notification.useNotification({
    // antd just proxies top value as is so we can pass here a string value
    // eslint-disable-next-line
    // @ts-ignore
    top: isMobile ? `calc(var(--q2-real-top, 0px) + 65px)` : 97,
  });

  const openNotification = useCallback(
    (params: NotificationParams) => {
      const callNotification =
        params.type === NotificationType.SUCCESS ? notificationApi.success : notificationApi.error;

      callNotification({
        message: params.message,
        description: params.description,
        placement: params.placement,
        ...getParamsByType(params.type),
      });
    },
    [notificationApi],
  );

  const contextValue = useMemo(() => ({ openNotification }), [openNotification]);

  return (
    <NotificationContext.Provider value={contextValue}>
      {notificationContextHolder}
      {children}
    </NotificationContext.Provider>
  );
};
