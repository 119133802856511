export const leftMenu = {
  root: 'csn--left-menu__root',
  logoImage: 'csn--left-menu__logo-image',
  logoImageMobile: 'csn--left-menu__logo-image-mobile',
  name: 'csn--left-menu__name',
  menuItem: 'csn--left-menu__menu-item',
  divider: 'csn--left-menu__divider',
};

export const header = {
  logo: 'csn--header__logo',
  logout: 'csn--logout',
};

export const login = {
  logo: 'csn--login__logo',
};

export const onboardingAlpaca = {
  logo: 'csn--onboarding-alpaca__logo',
};

export const unsupportedCountry = {
  container: 'csn--unsupported-country__container',
};

export const sideBar = {
  content: 'csn--sidebar__content',
};

export const rightSidePopup = {
  container: 'csn--right-side-popup__container',
};

export const topSection = {
  logo: 'csn--top-section__logo',
};

export const onboardingMainNavbar = {
  logo: 'csn--onboarding-main-navbar__logo',
};
