import React, { createContext, PropsWithChildren, useEffect, useMemo } from 'react';
import * as RD from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';

import { ApiError } from '@models/ApiError';
import { fromOption, sequenceT } from '@utils/RemoteData';
import { usePortfolioHoldings } from '~/entities/Holding';
import { AssetEntity, useAssetPerformance, useLoadAsset } from '~/entities/asset';
import { getWeekStartDate } from '@utils/graphOptions';

type HighlightedContextType = RD.RemoteData<
  ApiError,
  {
    info: AssetEntity;
    weekPerformance: number;
  }
>;

const defaultContextValue: HighlightedContextType = RD.initial;

export const HighlightedContext = createContext<HighlightedContextType>(defaultContextValue);

export const HighlightedContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [portfolioHoldings] = usePortfolioHoldings();
  const [highlightedAssetInfo, loadHighlightedAssetInfo] = useLoadAsset();
  const [highlightedWeeklyPerformance, loadHighlightedWeeklyPerformance] = useAssetPerformance();

  const highlightedTicker = useMemo(() => {
    return pipe(
      portfolioHoldings,
      RD.map(A.findFirstMap(({ isHighlighted, ticker }) => (isHighlighted ? O.some(ticker) : O.none))),
      RD.chain(fromOption(() => RD.failure({ code: 404, message: 'No highlighted found in portfolio' }))),
      RD.toNullable,
    );
  }, [portfolioHoldings]);

  useEffect(() => {
    if (highlightedTicker) {
      loadHighlightedAssetInfo(highlightedTicker);
      loadHighlightedWeeklyPerformance(highlightedTicker, { startDate: getWeekStartDate() });
    }
  }, [loadHighlightedAssetInfo, loadHighlightedWeeklyPerformance, highlightedTicker]);

  const contextValue = useMemo(
    () =>
      pipe(
        portfolioHoldings,
        RD.chain(() =>
          highlightedTicker ? sequenceT(highlightedAssetInfo, highlightedWeeklyPerformance) : RD.initial,
        ),
        RD.map(([info, weeklyPerformance]) => ({
          info,
          weekPerformance: weeklyPerformance.performance,
        })),
        RD.chain((company) =>
          company.weekPerformance < 0
            ? RD.failure({ code: 404, message: 'No company with positive weekly p erformance' })
            : RD.success(company),
        ),
      ),
    [portfolioHoldings, highlightedAssetInfo, highlightedWeeklyPerformance, highlightedTicker],
  );

  return <HighlightedContext.Provider value={contextValue}>{children}</HighlightedContext.Provider>;
};
