export const LANGS = ['en'] as const;
export const DEFAULT_LANG = LANGS[0];
export const LANG_REGEX = LANGS.slice(1).join('|');
export const DEFAULT_LANG_ROUTE = '';
// export const API_DOMAIN = process.env.NODE_ENV === 'development' ? 'http://localhost' : 'http://67.205.140.1';
export const TOKEN_LOCAL_STORAGE_NAME = 'eko-token';
export const REFRESH_TOKEN_LOCAL_STORAGE_NAME = 'eko-refresh-token';

export const API_DOMAIN = process.env.API_DOMAIN || '';
export const SENTRY_DSN =
  'https://224f64791dfdd13d9e7e2fd091008de5@o4504779634507776.ingest.us.sentry.io/4507406653521920';

export const IS_DEV_BUILD_ENV = process.env.NODE_ENV !== 'production';
export const IS_RUNTIME_PROD = window.location.origin.includes('app.eko');
export const RUNTIME_ENV = IS_RUNTIME_PROD ? 'production' : 'development';

export const PAGE_TITLE = 'Eko Investments';
export const DISPLAY_NAME = 'Eko';

export const logoURL = 'https://eko-app.s3.us-east-1.amazonaws.com/static/Logo.svg';
