import styled, { createGlobalStyle, css } from 'styled-components';

import { IconCheckMark } from '~/ui/kit/atoms/IconCheckMark';
import { IconCrossRounded } from '~/ui/kit/atoms/IconCrossRounded';

export const Container = styled('div')``;

const commonIconStyles = css`
  width: 20px;
  height: 20px;
`;

export const SuccessIcon = styled(IconCheckMark)`
  ${commonIconStyles};
  color: var(--eko-primary-color);
`;

export const ErrorIcon = styled(IconCrossRounded)`
  ${commonIconStyles};
  color: var(--eko-error-color);
`;

export const NotificationGlobalStyles = createGlobalStyle`
  .ant-notification {
    & .ant-notification-notice {
      .ant-notification-notice-message {
        margin-bottom: 0;
      }
      
      &-error {
        .ant-notification-notice-message {
          color: var(--eko-error-color);
        }
      }
    }
  }
`;
